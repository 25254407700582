























































import { Component, Vue } from 'vue-property-decorator';
import { readSubprefeituras } from '@/store/subprefeitura/getters';
import { dispatchDeleteSubprefeitura, dispatchGetSubprefeituras } from '@/store/subprefeitura/actions';
import { ISubprefeitura } from '@/interfaces/subprefeitura';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';
import ModalDetalhesSubprefeitura from '@/components/ModalDetalhesSubprefeitura.vue';

@Component({
  components: {
    ModalConfirmacao,
    ModalDetalhesSubprefeitura,
  },
})
export default class ListarSubprefeituras extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public modalDetalhesSubprefeituraAtivo: boolean = false;
  public subprefeituraExclusao;
  public subprefeituraDetalhes;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'center',
    },
    {
      text: 'Ações',
      align: 'center',
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get subprefeituras() {
    return readSubprefeituras(this.$store);
  }

  public abrirModalExclusao(subprefeitura: ISubprefeitura) {
    this.subprefeituraExclusao = subprefeitura;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteSubprefeitura(this.$store, this.subprefeituraExclusao);
    this.modalExclusaoAtivo = false;
  }

  public abrirModalDetalhesSubprefeitura(subprefeitura: ISubprefeitura) {
    this.subprefeituraDetalhes = subprefeitura;
    this.modalDetalhesSubprefeituraAtivo = true;
  }

  public async mounted() {
    await dispatchGetSubprefeituras(this.$store);
  }
}
